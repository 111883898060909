<template>
  <div class="edit-follow-status">
    <div class="header">
      <h3>变更品牌跟进状态</h3>
      <i
        class="el-icon-close"
        @click="hideInvestment()"
      ></i>
    </div>
    <div class="content">
      <div class="header-title">
        <div class="info-title">
          <div>品牌跟进信息</div>
        </div>
      </div>
      <div class="content-info">
        <div class="disabled-input">
          <div class="item">
            <div class="item-info">
              <div class="info-name">品牌名称<span>*</span></div>
              <div class="height36-input">
                <el-input
                  size='small'
                  v-model="invesmtentItem.brandName"
                  placeholder="-"
                  disabled
                ></el-input>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="item-info">
              <div class="info-name">当前跟进状态<span>*</span></div>
              <div class="height36-input">
                <el-input
                  size='small'
                  v-model="invesmtentItem.taskState"
                  placeholder="-"
                  disabled
                ></el-input>
              </div>
            </div>
          </div>
        </div>
        <div class="disabled-input">
          <div class="item">
            <div class="item-info">
              <div class="info-name">品牌跟进人<span>*</span></div>
              <div class="height36-input">
                <el-input
                  size='small'
                  v-model="invesmtentItem.followUserName"
                  placeholder="-"
                  disabled
                ></el-input>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="item-info">
              <div class="info-name">最近跟进时间<span>*</span></div>
              <div class="height36-input">
                <el-input
                  size='small'
                  v-model="invesmtentItem.lastFollowTime"
                  placeholder="-"
                  disabled
                ></el-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-select">
      <div class="header-title">
        <div class="info-title">
          <div>最新跟进信息</div>
        </div>
      </div>
      <div class="content-info">
        <div class="select-input">
          <div class="item">
            <div class="item-info">
              <div class="info-name">跟进状态<span>*</span></div>
              <div class="height36-input">
                <el-select
                  popper-class="w-block-select-down yellow-select-panel"
                  v-model="followState"
                  size='small'
                  placeholder="请选择"
                  @change="changeStatus"
                >
                  <el-option
                    v-for="item in statusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="item-info">
              <div class="info-name">跟进时间<span>*</span></div>
              <div class="height36-input">
                <el-date-picker
                  popper-class="black-el-date-picker"
                  v-model="followDate"
                  type="date"
                  placeholder="选择日期时间"
                  align="right"
                  :default-time="dateObj"
                >
                </el-date-picker>
              </div>
            </div>
          </div>
        </div>
        <div class="select-input textarea-input">
          <div class="item">
            <div class="item-info">
              <div class="info-name"> <i>备注<span style="margin-right: 0;">*</span></i></div>
              <div class="height36-input">
                <div class="textarea-wrap">
                  <el-input
                    type="textarea"
                    :rows="2"
                    placeholder="请输入"
                    v-model="remarksVal"
                  >
                  </el-input>
                  <div
                    class="tips-text"
                    v-if="tipsShow"
                  >当前字数超出100字</div>
                  <div
                    class="tips-len"
                    v-if="user.topuid !== 6467"
                  >{{remarksVal.length}}/100</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="info-btn">
      <span
        class="sure"
        @click="determineClick()"
      >保存</span>
      <span
        class="cancal"
        @click="hideInvestment()"
      >取消</span>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      remarksVal: '',
      followDate: '',
      followState: '',
      tipsShow: false,
      dateObj: dayjs().format('HH:mm:ss'),
      statusList: [{
        label: '已联系',
        value: '已联系'
      }, {
        label: '已看场',
        value: '已看场'
      }, {
        label: '已报价',
        value: '已报价'
      }, {
        label: '已预定',
        value: '已预定'
      }, {
        label: '已签约',
        value: '已签约'
      }, {
        label: '已搁置',
        value: '已搁置'
      }, {
        label: '无意向',
        value: '无意向'
      }],
      modulesList: []
    }
  },
  props: {
    invesmtentItem: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      token: 'token'
    }),
    domain () {
      return document.domain
    }
  },
  mounted () {
    console.log(this.invesmtentItem)
    this.getModularListFunc()
  },
  methods: {
    getModularListFunc () { // 获取工作台的菜单列表，这里用于判断有无权限调整相关页面
      this.axios.post(api.getModularList)
        .then((res) => {
          this.modulesList = res.data.data.filter(item => {
            return item.id === 12
          })
        })
    },
    hideVal () {
      this.remarksVal = ''
    },
    showVal () {
      if (this.remarksVal === '' || this.remarksVal === '输入跟进备注信息，限制100个字以内') {
        this.remarksVal = '输入跟进备注信息，限制100个字以内'
      }
    },
    hideInvestment () {
      this.$emit('hideInvestmentEmit')
    },
    changeStatus (type) {
      if (type === '已签约') {
        if (this.modulesList[0].flag) {
          if (this.modulesList[0].userFlag) {
            window.open(this.$gaodeDomain + '/omsviews/rent/contract/shopContract/add/mainBodyInfo?menuCode=12&tokenval=' + this.token, '_blank')
          } else {
            this.$message({
              type: 'warning',
              message: '抱歉，此账号暂无权限！'
            })
          }
        } else {
          this.$message({
            type: 'warning',
            message: '抱歉，集团暂未开通此应用模块！'
          })
        }
      } else if (type === '已预定') {
        if (this.modulesList[0].flag) {
          if (this.modulesList[0].userFlag) {
            window.open(this.$gaodeDomain + '/omsviews/rent/contract/intentionContract/add/mainBodyInfo?menuCode=12&tokenval=' + this.token, '_blank')
          } else {
            this.$message({
              type: 'warning',
              message: '抱歉，此账号暂无权限！'
            })
          }
        } else {
          this.$message({
            type: 'warning',
            message: '抱歉，集团暂未开通此应用模块！'
          })
        }
      }
    },
    async determineClick () {
      if (this.followState === '已签约' || this.followState === '已预定') {
        this.$message({
          type: 'warning',
          message: '「已预定」「已签约」状态将在合同创建成功后变更！'
        })
        return
      }
      if (!this.followState) {
        this.$message({
          type: 'warning',
          message: '请选择跟进状态!'
        })
        return false
      }
      if (this.followDate === '' && this.followState !== '已签约') {
        this.$message({
          type: 'warning',
          message: '请选择跟进时间!'
        })
        return false
      }
      if (this.user.topuid !== 6467) {
        if (this.remarksVal.length > 100) {
          this.tipsShow = true
          return false
        } else {
          this.tipsShow = false
        }
      }
      const params = {
        followRemark: this.remarksVal,
        followState: this.followState,
        taskId: this.invesmtentItem.taskId
      }
      if (this.followState !== '已签约') {
        params.followTime = this.followDate
      }
      if (this.domain === 'ysw.cpcity.vip' || this.domain === 'ysw-zs.cpcity.vip' || this.domain === 'local.winshangdata.com') {
        const res = await this.axios.post(api.countBrandLinkmanNums, { gbid: this.invesmtentItem.gbid })
        console.log(res)
        if (this.followState === '已联系' && res.data.data === 0) {
          this.jhcShowConfim(params)
        } else {
          this.axios.post(api.saveFollowUpStates, params)
            .then((res) => {
              if (res.data.code === 0) {
                this.$message({
                  type: 'success',
                  message: '修改跟进信息成功！'
                })
                this.remarksVal = ''
                this.$emit('hideInvestmentEmit', true)
                // if (this.followState === '已联系') {
                //   this.showConfim()
                // }
              }
            })
        }
      } else {
        this.axios.post(api.saveFollowUpStates, params)
          .then((res) => {
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: '修改跟进信息成功！'
              })
              this.remarksVal = ''
              this.$emit('hideInvestmentEmit', true)
              if (this.followState === '已联系') {
                this.showConfim()
              }
            }
          })
      }
    },
    showConfim () {
      this.axios.post(api.countPpyAddLink, { gbid: this.invesmtentItem.gbid })
        .then((res) => {
          if (res.data.data === 0) {
            this.$confirm('是否需要同步添加品牌联系人数据？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
              customClass: 'invitePosition'
            }).then((res) => {
              this.addContact()
            })
          }
        })
    },
    addContact () {
      let temUrl = ''
      if (process.env.NODE_ENV === 'development') {
        temUrl = 'http://' + document.domain + ':8030'
      }
      window.open(temUrl + '/brand/#/issue/addContact?brandId=' + this.invesmtentItem.brandId + '&gbid=' + this.invesmtentItem.gbid + '&brandName=' + encodeURIComponent(encodeURIComponent(this.invesmtentItem.brandName)) + '&taskId=' + this.invesmtentItem.id + '&from=investment', '_blank')
    },
    // 京华城无联系人弹窗添加
    jhcShowConfim (params) {
      this.$confirm('当前品牌无联系人，添加有效联系人后保存生效(点击确定前往添加联系人)', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'invitePosition'
      }).then((res) => {
        // console.log('confirm')
        console.log(params)
        const jsonStr = JSON.stringify(params)
        let temUrl = ''
        if (process.env.NODE_ENV === 'development') {
          temUrl = 'http://' + document.domain + ':8030'
        }
        window.open(temUrl + '/brand/#/issue/addContact?brandId=' + this.invesmtentItem.brandId + '&gbid=' + this.invesmtentItem.gbid + '&brandName=' + encodeURIComponent(encodeURIComponent(this.invesmtentItem.brandName)) + '&taskId=' + this.invesmtentItem.id + '&from=investment' + '&jsonStr=' + jsonStr, '_blank')
      }).catch((res) => {
        console.log(res)
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.header-title
  display flex
  margin-bottom 16px
  .info-title
    position relative
    font-size 16px
    padding-left 7px
    color #fff
    &:after
      content ''
      display inline-block
      position absolute
      left 0px
      top 5px
      width 2px
      height 14px
      background #F89407
      z-index 11
.info-btn
  position absolute
  bottom 0
  left 0
  width 100%
  height 76px
  border-top 1px solid #373A43
  padding-top 20px
  padding-left 286px
  background #272930
  span
    display inline-block
    width 130px
    height 36px
    line-height 36px
    text-align center
    color #fff
    background #F89407
    border-radius 4px
    margin-right 28px
    cursor pointer
    &.sure
      &:hover
        background-color #DB8307
    &.cancal
      background-color #373A43
    &.isDisabled
      background-color #81591b
      color rgba(255, 255, 255, 0.5)
.edit-follow-status
  height 100%
  .header
    display flex
    height 70px
    align-items center
    background-color #32343B
    padding 0 24px
    h3
      font-size 16px
      color #fff
      font-weight 800
    .el-icon-close
      margin-left auto
      color #fff
  .content
    padding 24px
    .content-info
      background-color #1e1e23
      padding 24px 24px 1px 0px
      .disabled-input
        display flex
        // justify-content center
        margin-bottom 34px
        .item
          .item-info
            display flex
            height 36px
            align-items center
            color #fff
            font-size 14px
            position relative
            .info-name
              color #B2B3BD
              width 120px
              text-align right
            span
              color #EE2D41
              margin-right 5px
              margin-left 2px
  .content-select
    padding 8px 24px
    .content-info
      background-color #1e1e23
      padding 24px 24px 1px 0px
      .select-input
        display flex
        padding-bottom 34px
        .item
          .item-info
            display flex
            height 36px
            align-items center
            color #fff
            font-size 14px
            position relative
            .info-name
              color #B2B3BD
              width 120px
              text-align right
            span
              color #EE2D41
              margin-right 5px
              margin-left 2px
      .textarea-input
        margin-top 24px
        padding-bottom 54px
        .info-name
          position relative
          i
            position absolute
            right 5px
            top -45px
.textarea-wrap
  position relative
  .tips-text
    position absolute
    left 0px
    bottom -22px
    color red
    font-size 14px
  .tips-len
    position absolute
    right 8px
    bottom 8px
    color #fff
</style>
