<template>
  <div class="add-singleBrand">
    <div class="single-wrap" :style="{height: (fullHeight - 230) + 'px'}">
      <i class="el-icon-close" @click="hideSingleBrand"></i>
      <h3>变更为已签约</h3>
      <div v-if="maskEdit !== 3" class="projectName">{{projectName}}</div>
      <div v-if="maskEdit === 3" class="projectName projectName2">
        <el-select
          :style="{width: inputNumber + 'px'}"
          popper-class="floorSelectPoper"
          @change="changeProject"
          v-model="projectName">
          <el-option
            v-for="item in projectList"
            :key="item.value"
            :label="item.label"
            :value="item">
          </el-option>
        </el-select>
      </div>
      <div class="input-wrap">
        <div class="singlebrnad-left">
          <div class="single-box">
            <p><i class="mustFill">*</i>签约品牌</p>
            <div class="search-wrap3">
              <el-input
                :class="[true ? 'is-disabled': '']"
                type="text"
                v-model="brandName"
                class="searchBrand"
                :disabled="true"
                placeholder="请输入你想添加的品牌名称"></el-input>
            </div>
          </div>
          <div class="single-box">
            <p>
              <i v-if="isRent === '1'" class="mustFill">*</i>计租方式：
              <el-tooltip class="item" effect="dark" placement="bottom">
                <i class="icon-font icon-question"></i>
                <div slot="content">计租方式为扣点的品牌租金收益将按照项目的平均标准租金进行测算</div>
              </el-tooltip>
            </p>
            <div class="search-wrap3">
              <el-select
                v-model="rentSelectStr"
                @change="changeSelectRent"
                popper-class="floorSelectPoper"
                placeholder="请选择">
                <el-option
                  v-for="item in rentList"
                  :key="item.value"
                  :label="item.label"
                  :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="single-box" v-if="rentCur === 2">
              <p><i v-if="isRent === '1'" class="mustFill">*</i>扣点比例：</p>
              <div class="search-wrap3">
                <el-input
                  type="number"
                  class="rentArea-input"
                  v-model="rentPercent"
                  placeholder="请输入扣点比例"></el-input>
                <i class="percentage" style="top: 10px;">%</i>
              </div>
            </div>
            <div class="search-wrap3" v-if="rentCur !== 2">
              <p><i v-if="isRent === '1'" class="mustFill">*</i>签约租金：</p>
              <el-input
                type="number"
                v-model="rentPrice"
                @keyup.native="number"
                class="searchBrand"
                placeholder="请输入月度租金标准"></el-input>
              <i class="percentage">元/月/m²</i>
            </div>
          </div>
          <div class="single-box">
            <p><i v-if="isLease === '1'" class="mustFill">*</i>租赁时间</p>
            <div class="search-mask">
              <el-date-picker
                v-model="rentTime"
                type="daterange"
                clearable
                @change="changeRent"
                value-format='yyyy-MM-dd'
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </div>
          </div>
        </div>
        <div class="singlebrnad-right">
          <div class="single-box">
            <p><i class="mustFill">*</i>签约铺位</p>
            <div class="search-mask">
              <el-cascader
                popper-class="floorCascaderPoper"
                :disabled="checked"
                v-model="selectFloor"
                placeholder="请选择签约铺位"
                :options="floorArr"
                filterable
                change-on-select
                @change="changeFloor"
              ></el-cascader>
            </div>
          </div>
          <div class="single-box">
            <p><i v-if="isArea === '1'" class="mustFill">*</i>租赁面积：</p>
            <div class="search-wrap3">
              <el-input
                type="number"
                v-model="rentArea"
                @keyup.native="number"
                class="rentArea-input"
                placeholder="请输入租金计算面积"></el-input>
              <i class="percentage">m²</i>
            </div>
          </div>
          <div class="single-box" v-if="rentCur === 3">
            <p><i v-if="isRent === '1'" class="mustFill">*</i>扣点比例：</p>
            <div class="search-wrap3">
              <el-input
                type="number"
                class="rentArea-input"
                v-model="rentPercent"
                placeholder="请输入扣点比例"></el-input>
              <i class="percentage">%</i>
            </div>
          </div>
          <div class="single-box" v-if="rentCur === 2">
            <p><i v-if="isRent === '1'" class="mustFill">*</i>预估租金：</p>
            <el-input
              type="number"
              v-model="rentPrice"
              @keyup.native="number"
              class="searchBrand"
              placeholder="请输入月度预估租金"></el-input>
            <i class="percentage">元/月/m²</i>
          </div>
          <div class="single-box">
            <p>免租时间</p>
            <div class="search-mask">
              <el-date-picker
                v-model="rentFreeTime"
                type="daterange"
                clearable
                value-format='yyyy-MM-dd'
                @change="changeFree"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="sing-remarks">
        <p>租赁补充：</p>
        <el-input
          type="textarea"
          v-model="rentRemark"
          placeholder="请补充其他租赁重要协议内容，诸如装补等"></el-input>
      </div>
      <div class="btn-wrap">
        <span class="cancal" @click="hideSingleBrand">取消</span>
        <span @click="determine()">保存</span>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
export default {
  data () {
    return {
      rentSelectStr: '固租+扣点(取其高)',
      rentList: [{ label: '固租+扣点(取其高)', value: 3 }, { label: '固租', value: 1 }, { label: '扣点', value: 2 }],
      rentCur: 3,
      rentFreeTime: [],
      selectFloor: [],
      rentTime: '',
      rentPrice: '',
      rentPercent: '',
      floor: '',
      berthId: '',
      checked: false,
      rentRemark: '',
      isRent: '0',
      isLease: '0',
      isArea: '0',
      fullHeight: 0,
      rentArea: '',
      projectList: [],
      brandName: '',
      inputNumber: 0,
      floorArr: []
    }
  },
  props: {
    invesmtentItem: {
      type: Object
    },
    projectId: {
      type: Number
    },
    maskEdit: {
      maskEdit: Number
    },
    projectName: {
      type: String
    }
  },
  watch: {
    fullHeight (val) {
      if (!this.timer) {
        this.fullHeight = val
        this.timer = true
        const that = this
        setTimeout(function () {
          that.timer = false
        }, 400)
      }
    }
  },
  mounted () {
    const that = this
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight
        that.fullHeight = window.fullHeight
      })()
    }
    window.addEventListener('click', (e) => {
      if (e.target.className === 'add-singleBrand') {
        this.hideSingleBrand()
      }
    })
    // console.log(this.maskEdit)
    // console.log(this.projectName)
    if (this.projectId) {
      this.getlistFloor(this.projectId)
    }
    if (this.maskEdit === 0) {
      this.brandName = this.invesmtentItem.brand.brandName
      if (this.invesmtentItem.floor) {
        this.selectFloor.push(this.invesmtentItem.building)
        this.selectFloor.push(this.invesmtentItem.floor)
        this.selectFloor.push(this.invesmtentItem.berthId)
        this.berthId = this.invesmtentItem.berthId
        this.getBerthArea()
      }
    } else if (this.maskEdit === 1) {
      if (this.invesmtentItem.floor) {
        this.selectFloor.push(this.invesmtentItem.building)
        this.selectFloor.push(this.invesmtentItem.floor)
        this.selectFloor.push(this.invesmtentItem.berthId)
        this.berthId = this.invesmtentItem.berthId
        this.getBerthArea()
      }
    } else if (this.maskEdit === 3) {
      this.brandName = this.invesmtentItem.brandName
      this.getBerthProjectFunc()
    }
    this.getSetting()
  },
  methods: {
    number () {
      this.rentPrice = this.rentPrice.replace(/[^.\d]/g, '')
      this.rentPrice = this.rentPrice.replace('.', '')
    },
    getBerthArea () {
      this.axios.post(api.getByBerthId, { berthId: this.berthId })
        .then((res) => {
          this.rentArea = res.data.data.useArea
        })
    },
    getBerthProjectFunc () {
      this.axios.post(api.getBerthProjectList)
        .then((res) => {
          this.projectList = res.data.data.map((item) => {
            let obj = {}
            obj = item
            obj.label = item.projectName
            obj.value = item.id
            return obj
          })
          this.projectName = this.projectList[0].label
          this.inputNumber = this.byteLength(this.projectName) * 11.5
          this.projectId = this.projectList[0].value
          this.getlistFloor(this.projectId)
        })
    },
    changeProject (item) {
      if (typeof (item) !== 'string') {
        this.getlistFloor(item.value)
        this.projectId = item.value
        this.inputNumber = this.byteLength(item.label) * 11.5
      }
    },
    getlistFloor (id) {
      this.axios.post(api.getFloorBuilding, { projectId: id })
        .then((res) => {
          this.floorArr = res.data.data
        })
    },
    changeSelectRent (item) {
      this.rentCur = item.value
    },
    getSetting () {
      this.axios.post(api.mngConfigSetting, { biz: 'berth_signed_form' })
        .then((res) => {
          this.isRent = res.data.data[0].value
          this.isLease = res.data.data[1].value
          this.isArea = res.data.data[2].value
        })
    },
    changeFloor (val) {
      this.floor = val[1]
      if (val[2]) {
        this.berthId = val[2]
        this.getBerthArea()
      }
    },
    hideSingleBrand () {
      this.$emit('hideSingleMask')
    },
    rentClick (index) {
      this.rentCur = index
    },
    changeRent (val) {
      if (val) {
        this.rentBeg = val[0]
        this.rentEnd = val[1]
      } else {
        this.rentTime = ''
        this.rentBeg = ''
        this.rentEnd = ''
      }
    },
    changeFree (val) {
      if (val) {
        this.freeBeg = val[0]
        this.freeEnd = val[1]
      } else {
        this.rentFreeTime = ''
        this.freeBeg = ''
        this.freeEnd = ''
      }
    },
    determine () {
      if (this.rentRemark.length > 200) {
        this.$message({
          type: 'warning',
          message: '租赁补充不能超过200字！'
        })
        return false
      }
      if (this.berthId === '') {
        this.$message({
          type: 'warning',
          message: '签约楼层和铺位不能为空！'
        })
        return false
      }
      const data = {}
      data.freeBeg = this.freeBeg
      data.freeEnd = this.freeEnd
      data.rentBeg = this.rentBeg
      data.rentEnd = this.rentEnd
      data.rentWay = this.rentCur
      data.rentArea = this.rentArea
      data.rentRemark = this.rentRemark
      if (this.rentCur === 1) {
        data.rentPercent = ''
        data.rentPrice = this.rentPrice
      } else if (this.rentCur === 2) {
        data.rentPercent = this.rentPercent
        data.rentPrice = this.rentPrice
      } else if (this.rentCur === 3) {
        data.rentPercent = this.rentPercent
        data.rentPrice = this.rentPrice
      }
      data.berthId = this.berthId
      data.brandId = this.invesmtentItem.brandId
      data.projectId = this.projectId
      data.gbid = this.invesmtentItem.gbid
      data.taskId = this.invesmtentItem.id
      this.axios.post(api.byGbidSynchronization, { gbid: this.invesmtentItem.gbid, projectId: this.projectId })
        .then((res) => {
          if (res.data.data) {
            data.taskId = res.data.data.id
            this.comfigFunc(data)
          } else {
            this.comfigFunc(data)
          }
        })
    },
    comfigFunc (data) {
      this.axios.post(api.saveSingleBrand, data)
        .then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '添加签约品牌成功！'
            })
            this.$emit('hideSingleMask', true)
          }
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
.add-singleBrand
  position fixed
  left 0
  top 0
  bottom 0
  right 0
  background rgba(0,0,0,.5)
  z-index 111
  .single-wrap
    position absolute
    left 50%
    margin-left -450px
    top 50px
    width 860px
    height 620px
    background #ffffff
    border-radius 6px
    padding 30px 20px
    z-index 1111
    overflow-y auto
    .el-icon-close
      position absolute
      right 20px
      top 15px
      color #D9D9D9
      cursor pointer
    h3
      color #333
      font-weight bold
.input-wrap
  .singlebrnad-left
    float left
    width 48%
    .single-box
      p
        color #333
        font-weight 500
        margin-bottom 10px
        margin-top 25px
        font-size 14px
        span
          font-size 14px
          font-weight 400
          color #999
          padding 3px 5px
          border 1px solid rgba(217,217,217,.5)
          cursor pointer
          &.g-rent
            border-radius 2px 0px 0px 2px
          &.l-rent
            border-radius 0px 2px 2px 0px
          &.active
            background #FFA134
            color #fff
            border 1px solid #FFA134
      .search-wrap3
        position relative
        .referInput
          margin-top 10px
        input
          width 380px
          height 32px
          line-height 32px
          padding-left 12px
          border-radius 2px
          border 1px solid rgba(236,236,236,1)
          cursor pointer
          font-size 14px
          box-sizing border-box
        .percentage
          position absolute
          right 47px
          top 36px
          color #333
          font-weight 400
          font-size 12px
      .search-wrap2
        position relative
        input
          width 189px
          height 32px
          line-height 32px
          padding-left 12px
          border-radius 2px
          border 1px solid rgba(236,236,236,1)
          font-size 14px
          box-sizing border-box
        .unit1
          position absolute
          right 232px
          top 10px
          color #333
          font-weight 400
          font-size 12px
        .unit2
          position absolute
          right 49px
          top 10px
          color #333
          font-weight 400
          font-size 12px
        .percentage
          position absolute
          right 232px
          top 10px
          color #333
          font-weight 400
          font-size 12px
        .percentage2
          position absolute
          right 40px
          top 10px
          color #333
          font-weight 400
          font-size 12px
  .singlebrnad-right
    float right
    width 44%
    .single-box
      position relative
      p
        color #333
        font-weight 500
        margin-bottom 10px
        margin-top 25px
        font-size 14px
      .search-mask
        position relative
        input
          width 190px
          height 32px
          line-height 32px
          padding-left 12px
          border-radius 2px
          border 1px solid rgba(236,236,236,1)
          font-size 14px
          box-sizing border-box
      .single-box
        position relative
      p
        color #333
        font-weight 500
        margin-bottom 10px
        margin-top 25px
        span
          font-size 14px
          font-weight 400
          color #999
          padding 3px 5px
          border 1px solid rgba(217,217,217,.5)
          cursor pointer
          &.g-rent
            border-radius 2px 0px 0px 2px
          &.l-rent
            border-radius 0px 2px 2px 0px
          &.active
            background #FFA134
            color #fff
            border 1px solid #FFA134
      .search-mask
        position relative
        input
          width 380px
          height 32px
          line-height 32px
          padding-left 12px
          border-radius 2px
          border 1px solid rgba(236,236,236,1)
        i
          position absolute
          right 50px
          top 10px
          color #333
          font-weight 400
          font-size 12px
      .search-mask2
        position relative
        input
          width 190px
          height 32px
          line-height 32px
          padding-left 12px
          border-radius 2px
          border 1px solid rgba(236,236,236,1)
          font-size 14px
          box-sizing border-box
  .search-wrap3
    position relative
    .percentage
      position absolute
      right 12px
      top 10px
      color #333
      font-weight 400
      font-size 12px
  .percentage
    position absolute
    right 12px
    top 36px
    color #333
    font-weight 400
    font-size 12px
.btn-wrap
  text-align center
  margin-top 20px
  span
    display inline-block
    width 200px
    height 40px
    line-height 40px
    text-align center
    color #fff
    background #FFA134
    font-size 16px
    font-weight 400
    border-radius 4px
    margin 0 10px
    cursor pointer
    &.cancal
      background #ffffff
      color #FFA134
      border 1px solid #FFA134
.search-list
  position absolute
  top 35px
  left 0px
  width 392px
  max-height 320px
  padding-top 1px
  z-index 11111
  background-color #fff
  overflow-y scroll
  box-shadow 2px 4px 10px #888
  border-radius 5px
  border-top 1px solid #ccc
  li
    height 36px
    line-height 36px
    text-align left
    text-indent 24px
    border-bottom 1px solid #cccccc
    font-size 14px
    cursor pointer
    &:hover
      color #FFA134
.single-wrap,
.search-list
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
      // background-color: #f2f2f2;
      border-radius 10px
  } /* 滚动条的滑轨背景颜色 */

  &::-webkit-scrollbar-thumb {
      background-color: #5f5f5f;
      border-radius 10px
  } /* 滑块颜色 */

  &::-webkit-scrollbar-button {
      // background-color: #000;
  } /* 滑轨两头的监听按钮颜色 */

  &::-webkit-scrollbar-corner {
      // background-color: black;
  } /* 横向滚动条和纵向滚动条相交处尖角的颜色 */
.brand-details
  border 1px solid rgba(255,161,52,1)
  box-shadow 0px 5px 8px 0px rgba(50,29,6,0.04)
  padding 10px
  margin-top 10px
  .brand-head
    height 32px
    line-height 32px
    span
      display inline-block
      width 23%
      color #999
      font-size 14px
      font-weight 500
  .brand-body
    height 32px
    line-height 32px
    span
      display inline-block
      width 23%
      color #333
      font-size 14px
      font-weight 500
.sing-remarks
  margin-top 20px
  p
    color #333
    font-size 14px
    margin-bottom 10px
  textarea
    box-sizing border-box
    width 100%
    height 100px
    resize none
    margin-top 10px
    border-radius 3px
    padding 10px
    font-size 14px
    appearance none
    border-style solid
.projectName
  width 100%
  background rgba(255,161,52,0.1)
  color #995F1D
  margin-top 14px
  height 40px
  line-height 40px
  font-size 16px
  padding-left 22px
  box-sizing border-box
.rentArea-input
  width 380px
  height 32px
  line-height 32px
  border-radius 2px
  cursor pointer
  font-size 14px
  box-sizing border-box
.projectName2
  padding-left 10px
</style>
